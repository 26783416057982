<template>
    <AppContainer>
    <BookingSalesCounter/>
    <GraphSection />
    <SummarySection />
    </AppContainer>
</template>

<script>
    import AppContainer from '@/components/containers/DashboardContainer.vue'
    import BookingSalesCounter from "@/components/dashboard/overview/BookingSalesCounter";
    import GraphSection from "@/components/dashboard/overview/GraphSection";
	import SummarySection from "@/components/dashboard/overview/SummarySection";
    //import AddWalkIn from '@/components/dashboard/booking/AddWalkIn.vue'
    export default {
        components: {
            AppContainer,
            BookingSalesCounter,
            GraphSection,
            SummarySection
            //AddWalkIn
        }
    }
</script>