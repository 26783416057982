<template>
   <!-- graph-area -->
        <div class="graph-area" v-if="this.user_role == 'business'">
            <div class="grap-title">
                <div class="title-grap">
                    <h4 class="fw-bold">Latest Activities</h4>
                </div>
            </div>
            <!-- Appointments-table-area  -->
            <div class="leads-table-area addcustomer-table addcampaigm-table">
                <table id="appt_table" class="display">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <!-- <th>Created Date</th> -->
                            <th>Customer</th>
                            <th>Provider</th>
                            <th>Price</th>
                            <th>Duration</th>
                            <th style="width:70px">Status</th>
                            <th>Schedule</th>
                        </tr>
                    </thead>
                    <tbody>
                    </tbody>
                </table>
            </div>
            <!-- Appointments-table-area  -->

        </div>
        <!-- graph-area -->
</template>

<script>
import axios from "axios";
import $ from "jquery";
import 'vue3-carousel/dist/carousel.css';

import "datatables.net";
import "datatables.net-responsive";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";



export default {
    name: "Appointments",
    data() {
        return {
            bookingType:2,
            is_owner: 1,
            employee_id: 0
            // breakpoints: {
            //     575: {
            //         itemsToShow: 4,
            //         itemsToScroll: 1,
            //         snapAlign: 'start',
            //     },
            //     // 700px and up
            //     992: {
            //         itemsToShow: 5,
            //         itemsToScroll: 1,
            //         snapAlign: 'start',
            //     },
            //     // 1024 and up
            //     1200: {
            //         itemsToShow: 7,
            //         itemsToScroll: 1,
            //         snapAlign: 'start',
            //     }
            // },
        };
    },
    
    created() {

    },
    methods: {

    },
    mounted() {
        this.user_role = this.$storage.getStorageSync('role')
        this.employee_id = this.$storage.getStorageSync('employee_id')
        let thiss = this;
        $("#appt_table").dataTable({
            "language": {
                        "infoEmpty": "Showing 1-5 of 10"
         },
            //order: [[7, "desc"]],
            responsive: true,
            fixedHeader: true,
            processing: true,
            serverSide: true,
            ajax: {
                url: axios.defaults.baseURL + "dt_appointments/business/"+this.$storage.getStorageSync("business").active_business.id+"/dashboard",
                type: "POST",
                data: function (d) {
                    d.duration = thiss.$route.params.type;
                },
                headers: {
                    Authorization: axios.defaults.headers.common['Authorization'],
                },
            },
            drawCallback: function () {
                //alert( 'Table redrawn '+new Date() );
            },
            columnDefs: [
                {
                    targets: [3, 4, 5],
                    orderable: false,
                },
            ],

        });

    },
    watch:{
        "$route.params.type": function () {
            if (this.$route.params.type != 'queue') {
                $("#appt_table").dataTable().fnDraw();
            }
        }
    }
};
</script>



<style scoped>
@import "../../../assets/css/dashboard/dataTables.css";
</style>
