<template>
 <div class="sale-area">
    <div class="row">
        <div class="col-md-6">
            <!-- sale-top-heading -->
            <div class="sale-top-heading" v-if="this.user_role  == 'business' ">
                <div class="select-option">
                    <form action="">
                        <div class="form-group">
                            <select
                            class="form-control"
                            v-model="filters_.id"
                            v-on:change="refreshData()">
                                <option selected="selected" value="">All Professionals</option>
                                <option
                                  v-for="emp in employees"
                                  :key="emp.id"
                                  :value="emp.id"
                                >
                                  {{ emp.user.first_name }}
                                </option>
                            </select>
                        </div>
                    </form>
                </div>
            </div>
            <!-- sale-top-heading -->
        </div>

        <div class="col-md-6">
        <div class="sale-timeline">
          <div class="timeline">
            <button
              type="button"
              v-bind:class="filters_.duration == 'today' ? 'current' : ''"
              v-on:click="refreshData()"
            >
              <span class="today">Today</span>
            </button>
            <button
              type="button"
              v-bind:class="filters_.duration == 'monthly' ? 'current' : ''"
              v-on:click="refreshData()"
            >
              <span class="today">This Months</span>
            </button>
            <button
              type="button"
              v-bind:class="filters_.duration == 'custom' ? 'current' : ''"
            >
              <date-range-picker
                :opens="'left'"
                :ranges="false"
                :locale-data="{ format: 'yyyy-mm-dd' }"
                v-model="filters_.customDate"
                @update="refreshData()"
              ></date-range-picker
              ><span class="month">Custom Day</span>
            </button>
          </div>
        </div>
      </div>


        <div class="col-12" v-if="this.user_role == 'business'">

            <!-- total-heading -->
            <div class="total-heading">
                <div class="totalheading-box">
                    <div class="media">
                        <img src="@/assets/images/overview/doller-icon.png" class="mr-3 img-fluid" alt="...">
                        <div class="media-body">
                            <h5 class="mt-0">Gross Sale </h5>
                            <h3>${{ salesCounter.gross }}</h3>
                        </div>


                        <div class="wrapper">
                          <div class="icon "  v-bind:class="grossSale ? 'increase' : 'decrease'">
                            <div class="tooltip">{{ salesCounter.grossPercent }}%</div>
                            <img v-if="grossSale" src="@/assets/images/overview/higher.svg" class="mr-3 img-fluid" alt="...">
                            <img v-if="!grossSale" src="@/assets/images/overview/lower.svg" class="mr-3 img-fluid" alt="...">
                          </div>
                        </div>




                    </div>
                </div>
                <div class="totalheading-box">
                    <div class="media">
                        <img src="@/assets/images/overview/currency-icon.png" class="mr-3 img-fluid" alt="...">
                        <div class="media-body">
                            <h5 class="mt-0">Net Sale</h5>
                            <h3>${{ salesCounter.net }}</h3>
                        </div>
                        <div class="wrapper">
                          <div class="icon " v-bind:class="netSale ? 'increase' : 'decrease'">
                            <div class="tooltip">{{ salesCounter.netPercent }}%</div>
                            <img v-if="netSale" src="@/assets/images/overview/higher.svg" class="mr-3 img-fluid" alt="...">
                            <img v-if="!netSale" src="@/assets/images/overview/lower.svg" class="mr-3 img-fluid" alt="...">
                          </div>
                        </div>

                    </div>
                </div>
                <div class="totalheading-box">
                    <div class="media">
                         <img src="@/assets/images/overview/appt-dollar.svg" class="mr-3 img-fluid" alt="...">
                        <div class="media-body">
                            <h5 class="mt-0">Appointments Sale</h5>
                            <h3>${{ salesCounter.appt }}</h3>
                        </div>
                         <div class="wrapper">
                          <div class="icon" v-bind:class="apptSale ? 'increase' : 'decrease'">
                            <div class="tooltip">{{ salesCounter.apptPercent }}%</div>
                            <img v-if="apptSale" src="@/assets/images/overview/higher.svg" class="mr-3 img-fluid" alt="...">
                          <img v-if="!apptSale" src="@/assets/images/overview/lower.svg" class="mr-3 img-fluid" alt="...">
                          </div>
                        </div>

                    </div>
                </div>
                <div class="totalheading-box">
                    <div class="media">
                         <img src="@/assets/images/overview/queue-sale.svg" class="mr-3 img-fluid" alt="...">
                        <div class="media-body">
                            <h5 class="mt-0">Queues Sale</h5>
                            <h3>${{ salesCounter.queue }}</h3>
                        </div>
                        <div class="wrapper">
                          <div class="icon" v-bind:class="queueSale ? 'increase' : 'decrease'">
                            <div class="tooltip">{{ salesCounter.queuePercent }}%  </div>
                              <img v-if="queueSale" src="@/assets/images/overview/higher.svg" class="mr-3 img-fluid" alt="...">
                              <img v-if="!queueSale" src="@/assets/images/overview/lower.svg" class="mr-3 img-fluid" alt="...">
                          </div>
                        </div>

                    </div>
                </div>
            </div>
            <!-- total-heading -->
        </div>

        <div class="col-md-6 col-lg-12" v-if="this.user_role == 'business' || this.user_role == 'professional' " >

            <!-- active-tabss -->
            <div class="active-tabss sale-active">
                <div class="tl">
                    <ul class="tl_btns">
                        <li class=""><a href="#">
                          <!-- <span>
                             <img class="img-fluid" src="http://bravo.ondaq.com/assets/app/img/future_appointment.47b036c6.svg" alt="">
                          </span> -->
                            <p class="d-none d-sm-block">Appointments</p><p class="d-block d-sm-none">Appt</p><h5>{{ bookingCounter.total_appts }}</h5> <img class="img-fluid" src="assets/img/shadow.svg" alt=""></a>
                        </li>


                        <li><a href="#">
                          <!-- <span>
                            <img class="img-fluid" src="http://bravo.ondaq.com/assets/app/img/today_appointments.5aebfbb6.svg" alt="">
                          </span> -->
                        <p>Completed</p><h5>{{ bookingCounter.comp_appts }}</h5><img class="img-fluid" src="assets/img/shadow.svg" alt=""></a></li>

                        <li class="seprate_group"><a href="#">
                          <!-- <span>
                             <img class="img-fluid" src="@/assets/images/overview/cancel-appt.svg" alt="">
                          </span> -->
                          <p>Canceled</p><h5>{{ bookingCounter.cancel_appts }}</h5><img class="img-fluid" src="assets/img/shadow.svg" alt=""></a>
                        </li>

                        <li class="vvv"><a href="#">
                          <!-- <span>
                             <img class="img-fluid" src="http://bravo.ondaq.com/assets/app/img/live_queue.4f35fb78.svg" alt="">
                          </span> -->
                            <p>Queues</p><h5>{{ bookingCounter.total_queues }}</h5><img class="img-fluid" src="assets/img/shadow.svg" alt=""></a>
                          </li>

                        <li><a href="#">
                          <!-- <span>
                            <img class="img-fluid" src="@/assets/images/overview/queue-com.svg" alt="">
                          </span> -->
                            <p>Completed</p><h5>{{ bookingCounter.comp_queues }}</h5><img class="img-fluid" src="assets/img/shadow.svg" alt=""></a>
                          </li>

                        <li><a href="#">
                          <!-- <span>
                            <img class="img-fluid" src="@/assets/images/overview/cancel-que.svg" alt="">
                          </span> -->
                            <p>Canceled</p><h5>{{ bookingCounter.cancel_queues }}</h5><img class="img-fluid" src="assets/img/shadow.svg" alt=""></a>
                          </li>

                    </ul>
                </div>

            </div>
            <!-- active -tabss -->
        </div>

    </div>
  </div>

</template>
<script>
// import $ from 'jquery'
// import DateRangePicker from "vue2-daterange-picker";
// import "vue3-daterange-picker/dist/vue3-daterange-picker.css";
// import DateRangePicker from './components/DateRangePicker.vue'
import axios from 'axios'
export default {
    // components: { DateRangePicker },
    data(){
    return{
      employees: [],
      filters_: {
        duration: "monthly",
        customDate: {},
        id: 0,
        from: "",
        to: "",
      },
      salesCounter: { gross: 0, net: 0, appt: 0, queue: 0 },
      bookingCounter: { total_appts: 0, comp_appts: 0, cancel_appts: 0, total_queues: 0, comp_queues: 0, cancel_queues: 0 },
      grossSale :true,
      netSale :true,
      apptSale :true,
      queueSale :true,
      user_role: "",
      employee_id: 0
    }
  
    },
    mounted() {
        this.user_role = this.$storage.getStorageSync('role')
        this.employee_id = this.$storage.getStorageSync('employee_id')
    },
    created() {
      if(this.user_role == "business")
      {
        this.getEmployees()
        this.getSaleStats();
      }
        this.getBookingStats();
    },
    methods: {
        getEmployees(){
            let thiss = this
            axios.get('employees_list').then(function (response) {
                if (response.data.status == 'success') {
                    thiss.employees = response.data.data
                    // console.log(thiss.employees)
                }
            })
            .catch(error => {
                if (error.response) {
                    console.log(error.response.data.message)
                }
            });
        },
        getSaleStats() {
          let thiss = this
          axios.post('sale_stats',thiss.filters_).then(function (response) {
              if (response.data.status == 'success') {
                console.log("Mehran");
                thiss.salesCounter = response.data.data;
                if(Math.sign(thiss.salesCounter.grossPercent) == -1){
                    thiss.grossSale = false;
                }
                if(Math.sign(thiss.salesCounter.netPercent) == -1){
                    thiss.netSale = false;
                }
                if(Math.sign(thiss.salesCounter.apptPercent) == -1){
                    thiss.apptSale = false;
                }
                if(Math.sign(thiss.salesCounter.queuePercent) == -1){
                    thiss.queueSale = false;
                }

              }else if (response.data.status == 'login') {
                  thiss.salesCounter = { gross: 0, net: 0, appt: 0, queue: 0 };
              }
            })
            .catch((err) => {
              console.log(err);
            });
        },
        getBookingStats() {
          let thiss = this
          axios.post('booking_stats',thiss.filters_).then(function (response) {
            if (response.data.status == 'success') {
              thiss.bookingCounter = response.data.data;
            }else if (response.data.status == 'login') {
                thiss.bookingCounter = { total_appts: 0, comp_appts: 0, cancel_appts: 0, total_queues: 0, comp_queues: 0, cancel_queues: 0 };
            }
          })
          .catch((err) => {
            console.log(err);
          });
        },
        refreshData() {
          if(this.user_role == "business")
          {
            this.getEmployees()
            this.getSaleStats();
          }
          this.getBookingStats();
        }
    }

}
</script>
<style scoped>
.member-status {
    font-size: 12px;
    font-weight: 800;
    letter-spacing: 1px;
}
.permissions-section {
    height: 62px;
    overflow: auto;
    overflow-x: hidden;
}
.wrapper .icon{
  cursor:pointer;
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:column;
  position: relative;
  z-index:2;
}
.wrapper .icon .tooltip{
  position:absolute;
  top:0px;
  background: #fff;
  box-shadow: 0 10px 10px rgba(0,0,0,0.1);
  font-size: 17px;
  padding: 4px 8px;
  border-radius: 25px;
  color:white;
  opacity:0;
  pointer-events: none;
  transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.wrapper .icon:hover .tooltip{
  opacity:1;
  pointer-events: auto;
  top:-50px;
}
.wrapper .icon .tooltip:before{
  position:absolute;
  content:"";
  height:15px;
  width:15px;
  bottom:-8px;
  left:50%;
  transform: translateX(-50%) rotate(45deg);
}
.wrapper .increase:hover span,
.wrapper .increase:hover .tooltip,
.wrapper .increase:hover .tooltip:before{
  background:#03b703;
}
.decrease:hover span,
.decrease:hover .tooltip,
.decrease:hover .tooltip:before{
  background:#fff;
}

.sale-active .tl_btns img {
  height: auto;
}
.sale-active .tl_btns .seprate_group {
  border-right: 1px solid #D5D8DF;
  height: 150px;
}
.sale-active{
    width: 100%;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgb(49 81 161 / 8%);
    border-radius: 8px;
    padding: 15px 30px;
    margin-bottom: 25px;
}
@media screen and (max-width:576px) {
    .sale-active .tl_btns .seprate_group {
      height: 120px;
    }

}
</style>