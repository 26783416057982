<template>
 <!-- graph-area -->
      <div class="salegrap-area">
          <div class="row">
               <div class="col-md-6 col-lg-4">
                  <div class="sale-grap salegrap4">
                      <div class="title-grap">
                          <h4>Appointments</h4>
                      </div>
                      <br>
                      <div class="graph-boxhome dashboard-appt-grap">
                      <apexcharts
                        width="100%"
                        height="250"
                        type="bar"
                        :options="apptChartOptions"
                        :series="apptChartData"
                      ></apexcharts>
                    </div>
                  </div>
              </div>

              <div class="col-md-6 col-lg-4">
                  <div class="sale-grap salegrap2">
                      <div class="title-grap">
                          <h4>Queues</h4>
                      </div>
                      <div class="graph-boxhome">
                        <apexcharts
                          type="bar"
                          width="100%"
                          height="260"
                          :options="sourceChartOptions"
                          :series="sourceChartData"
                        ></apexcharts>
                      </div>
                  </div>

              </div>
              <div class="col-md-6 col-lg-4">

                <div class="sale-grap salegrap2">
                      <div class="title-grap">
                          <h4>Booking Summary</h4>
                      </div>
                      <div class="graph-boxhome">
                        <apexcharts
                          type="donut"
                          width="100%"
                          height="260"
                          :options="chartOptions" :series="series"
                        ></apexcharts>
                      </div>
                  </div>


                 <!-- Funnel -->
                    <!-- <div class="funnel-box sale-funnel">
                      <div class="funnel-title">
                        <h3>Booking Summary</h3>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="funnel-img">
                            <img
                              class="img-fluid"
                              src="@/assets/images/overview/fanel2.svg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="funnel-list">
                            <ul>

                              <li>
                                <a href="#"
                                  ><span class="funnel-1"></span>Total Bookings
                                  <h5>{{ funnel.total_bookings }}</h5></a
                                >
                              </li>
                              <li>
                                <a href="#"
                                  ><span class="funnel-2"></span> Appointments
                                  <h5>{{ funnel.total_appts }}</h5></a
                                >
                              </li>
                              <li>
                                <a href="#"
                                  ><span class="funnel-3"></span> Queues
                                  <h5>{{ funnel.total_queues }}</h5></a
                                >
                              </li>
                              <li>
                                <a href="#"
                                  ><span class="funnel-4"></span> Deals
                                  <h5>{{ funnel.total_deals }}</h5></a
                                >
                              </li>
                              <li>
                                <a href="#"
                                  ><span class="funnel-5"></span> Qoutations
                                  <h5>{{ funnel.total_raq }}</h5></a
                                >
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="sale-table">
                        <div class="table-responsive">
                          <table class="table">
                            <thead>
                              <tr>
                                <th>Online Appointment </th>
                                <th>Walkin Appointment</th>
                                <th>Online Queues</th>
                                <th>walkin Queues</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{{ funnel.online_appt }}%</td>
                                <td>{{ funnel.walkin_appt }}%</td>
                                <td>{{ funnel.online_queue }}%</td>
                                <td>{{ funnel.walkin_queue }}%</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div> -->
                <!-- Funnel -->

              </div>

          </div>
      </div>
      <!-- graph-area -->
</template>

<script>

import axios from "axios";
import VueApexCharts from "vue3-apexcharts";

//import moment from 'moment'
//import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  name: "GraphSection",
  data() {
    return {
      graph_filters_: {
        duration: "monthly",
        customDate: {},
        from: "",
        to: "",
        id: ""
      },
      leadLoaded: false,
      typeLoaded: false,
      funnel: {},
      sourceChartOptions: {
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "15%",
            endingShape: "rounded",
            distributed: true,
          },
        },
        chart: {
          events: {
            click: (event, chartContext, config) => {
              this.$root.$emit("action", "activitygraph", {
                index: config.dataPointIndex,
              });
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: ["Walk In", "Online"],
        },
        yaxis: {
          title: {},
        },
        fill: {
          opacity: 1,
          //colors:['#008ffb', '#E91E63', '#9C27B0']
        },
        colors: ["#d9b7e8","#5d9965"],
      },
      sourceChartData: [],
      apptChartOptions: {
        plotOptions: {
          bar: {
            horizontal: true,
            startingShape: "rounded",
            endingShape: "rounded",
            barHeight: "22%",
            distributed: true,
          },
        },
        chart: {
          events: {
            click: (event, chartContext, config) => {
              this.$root.$emit("action", "appointmentgraph", {
                index: config.dataPointIndex,
              });
            },
          },
        },
        colors: ["#d9b7e8", "#65b587", "#c79f56", "#ad5355", "#c5c7c9"],
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        grid: { show: false },
        xaxis: {
          categories: ["Total", " Completed", "Pending", "Cancelled", "No-Show"],
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      apptChartData: [
        {
          data: [0, 0, 0, 0, 0],
        },
      ],
      series: [10,10,10,10,10],
      chartOptions: {
        chart: {
          type: 'donut',
        },
        labels: ['Total Bookings', 'Appointments', 'Queues', 'Deals',"Qoutations"], // Labels for the series
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
    };
  },
  components: {
    apexcharts: VueApexCharts,
  },
  created() {
    this.sourceQueue();
    this.bookingSummary();
    this.apptData();
  },
  methods: {
    sourceQueue() {

      let thiss = this
      axios.post('source_queue',thiss.graph_filters_).then(function (response) {
        if (response.data.status == 'success') {
          thiss.sourceChartData = response.data.data.counter;
        }else if (response.data.status == 'login') {
            thiss.apptChartData[0].data =  [0, 10, 0, 0, 0];
        }
      })
      .catch((err) => {
        console.log(err);
      });

    },
    bookingSummary() {
      let thiss = this
      axios.post('booking_summary',thiss.graph_filters_).then(function (response) {
        if (response.data.status == 'success') {
          thiss.funnel = response.data.data;
         
          thiss.series = [
            thiss.funnel.total_bookings > 1 ? thiss.funnel.total_bookings : 1,
            thiss.funnel.total_appts > 1 ? thiss.funnel.total_appts : 1,
            thiss.funnel.online_queue > 1 ? thiss.funnel.online_queue : 1,
            thiss.funnel.total_deals > 1 ? thiss.funnel.total_deals : 1,
            thiss.funnel.total_raq > 1 ? thiss.funnel.total_raq : 1
          ];
          // thiss.series[1] = thiss.funnel.total_bookings

        }
      })
      .catch((err) => {
        console.log(err);
      });
    },
    apptData() {
      let thiss = this
      axios.post('appt_counter',thiss.graph_filters_).then(function (response) {
        if (response.data.status == 'success') {
          thiss.apptChartData = response.data.data.counter;
          // setTimeout(() => {
          //     this.apptLoaded = true;
          // }, 1000);
        }else if (response.data.status == 'login') {
            thiss.apptChartData[0].data =  [0, 10, 0, 0, 0];
        }
      })
      .catch((err) => {
        console.log(err);
      });
    },
  },
};
</script>

<style scoped>
  .sale-funnel .table thead th {
    font-size: 13px ;
    font-weight: 700 ;
    background: #f3edf5;
    color: black;
}
.vue-apexcharts{
    margin-top: -12px;
}
.dashboard-appt-grap{
  margin-top: -53px;
}
.sale-funnel .funnel-img{
  margin-top: 19px;
}
</style>